#FAQ {
  background: #f9faf7;
}
#FaqPage {
  display: flex;
  justify-content: center;
  gap: 20px;
  align-items: center;
}

#HomeChart > img {
  width: 40vw;
  border-radius: 30px;
}
.FaqCard {
  color: #222f38;
  width: 90%;
  max-width: 50rem;
  min-height: 30rem;
  height: auto;
  border-radius: 2rem;
}
.faqText {
  color: #222f38;
}

.card:hover .img {
  filter: drop-shadow(0rem 2.5rem rgba(0, 0, 0, 0.1));
}

.title {
  text-align: center;
  font-size: 5rem;
  padding: 1rem;
}

.acc-container {
  padding: 4rem 2rem;
}
.acc-btn {
  width: 100%;
  padding: 1.6rem 2rem;
  font-size: 1.6rem;
  cursor: pointer;
  background: inherit;
  border: none;
  outline: none;
  text-align: left;
  transition: all 0.1s ease-in-out;
}
.acc-btn:after {
  content: "\27A4";
  color: #222f38;
  float: right;
  transition: all 0.1s ease-in-out;
}
.acc-btn.is-open:after {
  transform: rotate(90deg);
}
.acc-btn:hover,
.acc-btn.is-open {
  color: #222f38;
  font-weight: bold;
}

.acc-content {
  max-height: 0;
  color: rgba(0, 0, 0, 0.75);
  font-size: 1.5rem;
  margin: 0 2rem;
  display: flex;
  padding-left: 1rem;
  overflow: hidden;
  transition: max-height 0.1s ease-in-out;
  border-bottom: 1px solid #222f38;
}

.credit {
  text-align: center;
  padding: 1rem;
}
.credit a {
  text-decoration: wavy underline;
  color: dodgerblue;
}
@media only screen and (max-width: 769px) {
  #HomeChart {
    display: none;
  }
}
