#StudyMaterialDocumentDownload {
  display: flex;
  align-items: center;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  bottom: 2%;
  background-color: rgb(93, 93, 255);
  padding: 2px 0;
  font-size: large;
  padding: 5px 20px;
  border-radius: 20px;
  color: white;
}
#StudyMaterialDocumentDownload > div > svg {
  width: 24px;
  stroke: white;
  margin: 0 5px;
  height: 24px;
}

#StudyMaterialDocumentImage > img {
  margin: 20px 0;
  border: solid 2px black;
  width: 80vw;
}
