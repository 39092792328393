#privacy-policy {
  /* Main container ID */
  margin: 0 auto;
  padding: 20px 5vw;
}

#privacy-policy h1 {
  /* Title within container */
  font-size: 20px;
  background-color: #332cf2;
  color: #fff;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#privacy-policy p {
  text-align: justify;
  margin: 5px 0;
  font-size: 14px;
  /* Paragraphs within container */
  line-height: 1.5;
}

#privacy-policy h2 {
  text-align: justify;
  font-size: 22px;
  /* Headings within container */
  margin-top: 20px;
}
#privacy-policy h3 {
  text-align: justify;
  font-size: 18px;
  /* Headings within container */
  margin-top: 20px;
}

#privacy-policy ul li ul li {
  text-align: justify;
  list-style: decimal;
  font-size: 14px;
  margin-left: 20px;
}
