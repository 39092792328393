.slideshow-container {
  position: relative;
  background: #121212;
  padding: 20px 100px;
}
.slideshow-container > h1 {
  font-size: 40px;
  color: #f9b234;
  margin-bottom: 70px ;
  padding: 20px 0;
}
.TestimonialImage{
  
  border-radius: 100%;
  background: #ddd;
  width: 200px;
  height: 200px;
}
.TestimonialDivBox{
  display: flex;
  align-items: center;
  color: black;
  transform: translateX(-50%);
  position: absolute;
  left: 50%;
  top:110px;
}
.TestimonialDivBox>p{
  font-family:'Times New Roman', Times, serif;
  margin: 0 40px;
  font-size: 100px;
  color:#f9b234;
 
}

.mySlides {
  display: none;
  padding: 0 80px;
  padding-top: 120px ;
  border-radius: 20px;
  background: #fff;
  display: flex;

  text-align: center;
}

.mySlides > q {
  font-size: 2rem;
}
.mySlides > p {
  font-size: 1.7rem;
  padding-bottom: 20px;
}

/* Next & previous buttons */
.prev,
.next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  margin-top: -30px;
  padding: 16px;
  color: white;
  font-weight: bold;
  font-size: 20px;
  border-radius: 0 3px 3px 0;
  user-select: none;
  background: #000;
}
.prev {
  position: absolute;
  left: 0;
  border-radius: 3px 0 0 3px;
}
/* Position the "next button" to the right */
.next {
  position: absolute;
  right: 0;
  border-radius: 3px 0 0 3px;
}

/* On hover, add a black background color with a little bit see-through */
.prev:hover,
.next:hover {
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
}

/* The dot/bullet/indicator container */
.dot-container {
  text-align: center;
  padding: 10px;
  background: #ddd;
}

/* The dots/bullets/indicators */
.dot {
  cursor: pointer;
  height: 9px;
  width: 9px;
  margin: 0 2px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}

/* Add a background color to the active dot/circle */
.active,
.dot:hover {
  background-color: #717171;
}

/* Add an italic font style to all quotes */
q {
  font-style: italic;
}

/* Add a blue color to the author */
.author {
  color: cornflowerblue;
}

@media (max-width: 768px) {
  .slideshow-container {
    padding: 20px 10px;
  }
  .TestimonialDivBox>p{
    margin: 0 2px;
  }

}