#CourseLinkBox {
  height: 30vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0 40px;
}
#CourseLinkBox > div > h4 > a {
  color: black;
}
#CourseLinkH {
  padding: 0 60px;
  font-size: 32px;
  margin-top: 10px;
  color: #332cff;
}
#CoursesNav {
  background: #121212;
  color: #ffffff;
}
#CoursesNav > ul {
  display: flex;
  align-items: center;
  gap: 20px;
  height: 40px;
  margin: 0 100px;
  overflow: hidden;
}
#CoursesNav > ul > li > a {
  color: white;
  font-size: 2rem;
}
.ActiveNav {
  color: #20f8be;
  border-bottom: solid 3px #20f8be;
}
#CoursesBox {
  background: #f9faf7;

  display: flex;
  flex-direction: column;
}
#CoursesBox > div {
}
#CoursesBox > div > h2 {
  color: #222f38;
  font-size: 50px;
}
#Courses {
  display: flex;
  justify-content: center;
  padding: 5px 10px;

  flex-wrap: wrap;
}
.course {
  margin: 0 30px;
  width: 370px;
}
#MainCourseBanner {
  display: flex;
  flex-direction: column;
  border-radius: 20px;

  background: white;
  margin: 20px 0;
  border: solid 2px black;
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px,
    rgba(0, 0, 0, 0.07) 0px 4px 4px, rgba(0, 0, 0, 0.07) 0px 8px 8px,
    rgba(0, 0, 0, 0.07) 0px 16px 16px;
  padding: 0 20px;
  text-align: center;
}

#CoursePic {
  display: flex;
  justify-content: center;
  overflow: hidden;
  height: 150px;
  width: 300px;
}

#MainCourseBanner {
  object-fit: fill;
}

#MainCourseBanner:hover {
}
#CourseSubheading::before {
  content: "";
  position: absolute;
  right: 100%;
  top: 0px;
  width: 0;
  height: 0;
  border-top: 0px solid transparent;
  border-right: 20px solid #20f8be;
  border-bottom: 32px solid transparent;
}
#CourseSubheading::after {
  content: "";
  position: absolute;
  right: 100%;
  bottom: 0px;
  width: 0;
  height: 0;
  border-top: 32px solid transparent;
  border-right: 20px solid #20f8be;
  border-bottom: 0px solid transparent;
}
#CourseSubheading {
  background: #20f8be;
  padding: 2px 10px;

  position: relative;
  /* -moz-border-radius: 10px; */
  /* -webkit-border-radius: 10px; */
  /* border-radius: 10px; */
}
#CourseBanner1 > h4 {
  text-align: left;
  font-size: 20px;
}
#CourseBanner1 {
  margin: 25px 0;
  font-size: 22px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
#CourseBanner2 {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  font-size: 20px;
  color: white;
  margin: 20px 0;
  background: #332cff;
  height: 50px;
  border-radius: 100px;
}
#CourseBanner2Line {
  width: 3px;
  height: 40px;
  background: white;
}

#CourseBanner3 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 18px;
  margin: 10px 0;
}
#CourseBanner3 > h5 {
  text-align: left;
}
#CourseBanner4 > h5 {
  text-align: left;
  margin: 10px 0;
}
#CourseBanner5 {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  font-size: 20px;
  color: white;
  margin-top: 20px;
  background: #121212;
  height: 50px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  border-radius: 10px 10px 0 0;
}

#CourseBanner5 > h5 {
  background-image: linear-gradient(
    to right,
    #20f8be,
    #20f8be 50%,
    #ffffff 50%
  );
  background-size: 200% 100%;
  background-position: -100%;
  display: inline-block;
  padding: 5px 0;
  position: relative;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: all 0.3s ease-in-out;
}

#CourseBanner5 > h5:before {
  content: "";
  background: #20f8be;
  display: block;
  position: absolute;
  bottom: -3px;
  left: 0;
  width: 0;
  height: 3px;
  transition: all 0.3s ease-in-out;
}

#CourseBanner5 > h5:hover {
  background-position: 0;
}

#CourseBanner5 > h5:hover::before {
  width: 100%;
}

#CourseBanner5Line {
  background: white;
  height: 40px;
  width: 2px;
}
.buttonsCourses {
  cursor: pointer;
}
@media (max-width: 768px) {
  #CoursesNav > ul > li > a {
    font-size: 1rem;
    text-wrap: wrap;
  }
  #MainCourseBanner {
    width: 300px;
  }

  #CoursesNav > ul {
    margin: 2px;
  }
}

@media (max-width: 400px) {
  #MainCourseBanner {
    width: 95vw;
  }
  #CoursePicMain {
    width: 80vw;
  }
  #CoursePic {
    width: 80vw;
  }
}
