/* HomeTution.css */

/* General styles */

.HTcontainer {
  max-width: 800px;
  margin: auto;
  padding: 20px;
}
.HTnote {
  color: red;
  font-weight: bold;
  padding: 10px 0;
  font-size: 20px;
}
/* Header styles */
.HTheader {
  text-align: center;
  margin: 10px 0;
}

.HTheader h1 {
  font-size: 8rem;
}

.HTheader h2 {
  font-size: 4rem;
  text-align: center;
}

/* Main section styles */
.HTmain {
  font-size: 3rem;
}
.HTmain p {
  font-size: 2rem;
}

.HTsection {
  margin: 40px 0;
}
.HTsectionDetail {
  font-size: 2.4rem;
}
.HTproducts {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
/* Form styles */
.HTinput-field,
.HTselect-field {
  width: 400px;
  padding: 10px;
  margin: 10px;
  display: flex;
  font-size: 20px;
  text-align: left;
  text-align-last: left;
  -moz-text-align-last: center;
  padding-left: 20px;
  justify-content: left;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}
.HTinputForm {
  display: flex;
  justify-content: center;
}

.HTbookbutton {
  padding: 10px 20px;
  font-size: 30px;
  background-color: #2c097f;
  color: #fff;
  border: solid 2px black;
  border-radius: 5px;
  cursor: pointer;
  margin: 20px 20px;
}
.HTbutton {
  padding: 10px 20px;
  font-size: 20px;
  background-color: #2c097f;
  color: #fff;
  border: solid 2px black;
  border-radius: 5px;
  cursor: pointer;
  margin: 20px 20px;
}

.HTbookbutton:hover {
  background-color: #2c097f;
}
.HTbutton:hover {
  background-color: #2c097f;
}

/* List styles */
.HTlist {
  list-style: none;
  display: flex;
  justify-content: center;
  padding: 10px 0;
}

.HTlist-item {
  margin: 10px 0;
  padding: 10px;
  width: 400px;
}
.HTlist-item > img {
  width: 4em;
}
.HTlistBox {
  margin: 20px 0;
}
.HTlist2 {
  list-style: none;
  display: flex;
  justify-content: center;
  padding: 10px 0;
}

.HTlist-item2 {
  padding: 10px;
  font-weight: bold;
  width: 500px;
}

.HTlistBox2 {
  margin: 0px 10px;
}
.HTlistBox2 > li {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 50px 0;
  border-radius: 10px;
  border: #2c097f solid 2px;
  width: 400px;
  height: 100px;
}
.HTlist3 {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.HTlist-item3 {
  margin: 20px 4rem;
}
.HTlist-item3 > img {
  width: 150px;
}
.HTlist-item3 > p {
  font-weight: bold;
}
/* Image styles */
.HTimage {
  max-width: 100%;
}

#HTProducts {
  display: flex;
  flex-wrap: wrap;
}
#HTMainProductBanner {
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  background: white;
  margin: 20px 30px;
  border: solid 2px black;
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px,
    rgba(0, 0, 0, 0.07) 0px 4px 4px, rgba(0, 0, 0, 0.07) 0px 8px 8px,
    rgba(0, 0, 0, 0.07) 0px 16px 16px;
  padding: 0 20px;
  width: 300px;
  height: 400px;
  text-align: center;
}

#HTProductPic {
  display: flex;
  justify-content: center;
  overflow: hidden;
  /* height: 200px; */
  width: 300px;
}
#HTProductPic > img {
  display: flex;
  justify-content: center;
  overflow: hidden;
  /* height: 200px; */
  width: 80%;
}
#HTMainProductBanner {
  object-fit: fill;
}

#HTMainProductBanner:hover {
}
#HTProductSubheading::before {
  content: "";
  position: absolute;
  right: 100%;
  top: 0px;
  width: 0;
  height: 0;
  border-top: 0px solid transparent;
  border-right: 20px solid #20f8be;
  border-bottom: 32px solid transparent;
}
#HTProductSubheading::after {
  content: "";
  position: absolute;
  right: 100%;
  bottom: 0px;
  width: 0;
  height: 0;
  border-top: 32px solid transparent;
  border-right: 20px solid #20f8be;
  border-bottom: 0px solid transparent;
}
#HTProductSubheading {
  background: #20f8be;
  padding: 2px 10px;

  position: relative;
  /* -moz-border-radius: 10px; */
  /* -webkit-border-radius: 10px; */
  /* border-radius: 10px; */
}
#HTProductBanner1 > h4 {
  /* padding: 20px; */
  text-wrap: wrap;
}
#HTProductBanner1 {
  margin: 25px 0;
  font-size: 22px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
#HTProductBanner2 {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  font-size: 20px;
  color: white;
  margin: 20px 0;
  background: #2c097f;
  height: 50px;
  border-radius: 100px;
}
#HTProductBanner2Line {
  width: 3px;
  height: 40px;
  background: white;
}

#HTProductBanner3 {
  display: flex;
  text-align: left;
  flex-direction: column;
  list-style: disc;
  align-items: flex-start;
  font-size: 14px;
  margin: 10px 40px;
  font-weight: 700;
}
#HTProductBanner3 > li {
  text-align: left;
}
#HTProductBanner3 > h5 {
  text-align: left;
}
#HTProductBanner4 > h5 {
  text-align: left;
  margin: 10px 0;
}
#HTProductBanner5 {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  font-size: 20px;
  color: white;
  margin-top: 20px;
  background: #121212;
  height: 50px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  border-radius: 10px 10px 0 0;
}

#HTProductBanner5 > h5 {
  background-image: linear-gradient(
    to right,
    #20f8be,
    #20f8be 50%,
    #ffffff 50%
  );
  background-size: 200% 100%;
  background-position: -100%;
  display: inline-block;
  padding: 5px 0;
  position: relative;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: all 0.3s ease-in-out;
}

#HTProductBanner5 > h5:before {
  content: "";
  background: #20f8be;
  display: block;
  position: absolute;
  bottom: -3px;
  left: 0;
  width: 0;
  height: 3px;
  transition: all 0.3s ease-in-out;
}

#HTProductBanner5 > h5:hover {
  background-position: 0;
}

#HTProductBanner5 > h5:hover::before {
  width: 100%;
}

#HTProductBanner5Line {
  background: white;
  height: 40px;
  width: 2px;
}
.HTbuttonProducts {
  cursor: pointer;
}

#HTTutors {
  display: flex;
  transform: scale(0.8);
  justify-content: center;
}

/* Responsive styles */
@media (max-width: 768px) {
  .HTcontainer {
    padding: 10px;
  }
  .HTinputForm {
    flex-direction: column;
  }
  .HTinput-field,
  .HTselect-field {
    width: 90vw;
  }
  #HTTutors {
    flex-direction: column;
    transform: scale(1);
    align-items: center;
    justify-content: flex-start;
  }
  .HTlist {
  }
  .HTlist-item {
    width: 200px;
  }
  .HTlist-item3 > img {
    width: 100px;
  }

  .HTlist2 {
    flex-wrap: wrap;
  }
  .HTlist3 {
    flex-wrap: wrap;
  }
  .HTlistBox2 > li {
    margin: 0;
    margin-bottom: 20px;
  }
  .HTheader h1 {
    font-size: 6rem;
  }
  .HTlistBox2 > li {
    width: 15em;
  }
}
