.contact-us {
  font-family: Arial, sans-serif;
  padding: 20px;
  max-width: 600px;
  margin: 0px auto;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #f9faf7;
}

.contact-us h1 {
  font-size: 3em;
  margin-bottom: 0.5em;
  color: #222f38;
}

.contact-us p {
  font-size: 2em;
  text-align: justify;
  margin-bottom: 1em;
  line-height: 1.6;
  color: #555;
}

.contact-us a {
  color: #007bff;
  text-decoration: none;
}

.contact-us a:hover {
  text-decoration: underline;
}

.contact-us address {
  font-size: 2em;
  font-style: normal;
  line-height: 1.6;
  color: #555;
  margin-top: 1em;
  border-top: 1px solid #ddd;
  padding-top: 1em;
}
