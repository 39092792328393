#heroAbout {
  padding: 100px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f9faf7;
  color: #222f38;
}
#heroAbout > div > h2 {
  text-align: justify;
}
#heroAbout > div {
  padding: 0 100px;
  display: flex;
  align-items: center;
  font-size: 16px;
  text-align: left;
  gap: 30px;
}
#heroAbout > div > img {
  box-shadow: 0 0 0 3px rgb(255, 255, 255), 10px 10px 0 0 rgb(21, 2, 57);
}

#About1 {
  width: 400px;
}
#AboutBox2 {
  margin: 80px 0;
}
#AboutBox2 > h2 {
  font-size: 32px;
}
#AboutBox2 > ul {
  margin: 20px 50px;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}
#AboutBox2 > ul > li {
  display: flex;

  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  padding: 10px;
  background-image: linear-gradient(135deg, #332cff, #4225b7);
  margin: 20px;
  font-size: 20px;
  width: 300px;
  overflow: hidden;
  color: white;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

#AboutBox2 > ul > li > img {
  height: 250px;
  width: fit-content;

  margin: 20px 0;
  border-radius: 10px;
}
.AboutBox3 {
  /* background: #332cff; */
  padding: 80px 0;
  color: #222f38;
  background: #f9faf7;
}
.AboutBox3 > h2 {
  font-size: 32px;
}
.AboutBox3 > ul {
  margin: 40px 0;
  display: flex;

  align-items: center;
  flex-direction: column;
}
.AboutBox3 > ul > div {
  margin: 20px 0;
  text-wrap: wrap;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  gap: 4rem;
}
.AboutBox3 > ul > div > li > h3 {
  font-size: 18px;
  display: flex;
  background: white;
  color: black;
  box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px,
    rgba(6, 24, 44, 0.65) 0px 4px 6px -1px,
    rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
  height: 50px;
  padding: 10px;
  align-items: center;
  justify-content: center;
}
.AboutBox3 > ul > div > li {
  width: 500px;
  margin: 5px 0;
}

#About4 {
  padding: 80px 0;
  display: flex;
  justify-content: left;
}

#About4 > div > div > div {
  display: flex;
  overflow: hidden;
  justify-content: center;
  width: 100vw;
  margin: 20px 0;
  padding: 0 80px;
}
#MissionImages {
  height: 250px;
}
#About4 > div > div > div > img {
  margin: 0 2px;
}
#About4 > div > div {
  margin: 50px 0;
  text-align: justify;
}
#About4 > div > div > div > p {
  text-align: justify;
  font-size: 20px;
  font-weight: 600;
}
#About4 > div > h2 {
  font-size: 32px;
}
@media (max-width: 720px) {
  #About1 {
    width: 80vw;
  }
  #AboutBox2 {
    margin: 10px 0;
  }
  .AboutBox3 {
    padding: 20px 0;
  }
  .AboutBox3 > ul {
    margin: 0;
  }
  .AboutBox3 > ul > div > li > h3 {
    font-size: 1.7rem;
  }
  .AboutBox3 > ul > div > li {
    width: 100vw;
  }
  #About4 > div > div > div > p {
    font-size: 1.9rem;
  }
  #About4 > div > div {
    margin: 0;
  }
  #About4 {
    padding: 10px 0;
  }

  #heroAbout > div > h2 {
    font-size: 1.9rem;
  }
  #AboutBox2 > ul {
    margin: 0 0;
  }
}
@media (max-width: 1024px) {
  #About4 > div > div > div {
    padding: 0 2vw;
  }
  #heroAbout > div {
    padding: 0 2vw;
  }

  #heroAbout > div {
    flex-direction: column-reverse;
  }
}
