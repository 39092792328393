* {
  margin: 0;
  padding: 0;
  text-align: center;
}
section {
  /* height: 60vh; */
}
/* HERO */
#Hero {
  display: flex;

  /* justify-content: flex-end; */
  /* padding-top: 20vh; */
}
/*  */
#ResultBox {
  background: #5d17eb;
}
#HeroBox {
  height: auto;
  background: #5d17eb;
  width: 100vw;
  padding: 10vh 0px;
  color: white;
}
#HeroBox > h1 {
  font-size: 40px;
  margin: 0 100px;
}
.Typewriter__cursor {
  display: none;
}
#HeroBox > h2 {
  margin: 0 100px;
  font-size: 28px;
  position: relative;
}
.Typewriter {
  display: flex;
  justify-content: flex-start;
}
.Typewriter > span {
  text-align: left;
}
#HeroBookSession {
  background: white;
  padding: 20px 40px;
  border-radius: 10px;
  font-weight: 600;
  margin: 30px auto;
  margin-left: 40vw;
  /* position: absolute; */

  border: double 2px black;
}
#HeroBookSession:hover {
  background: #332cf2;
  color: white;
  border-color: white;
  transition: all 0.1s ease-in;
}

.info {
  height: 100vh;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.infoBox {
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50vw;
}
.infoBoxTab {
  height: 200px;
  width: 300px;
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* border: solid 2px #332cff; */
  border-radius: 20px;
  margin: 10px;
  font-size: 32px;
  color: #332cff;
  font-weight: bolder;
}
.infoBoxTab > p {
  margin: 5px 0;
}

#ExamBox > h2 {
  color: #222f38;
  font-size: 22px;
  padding: 20px 0;
}
.OnlinePresence {
  height: 100vh;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.OnlinePresenceBox {
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.OnlinePresenceBoxTab {
  height: 200px;
  width: 300px;
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: solid 2px blue;
  border-radius: 20px;
  margin: 10px;
  font-size: 32px;
  color: blue;
  font-weight: bolder;
}
.OnlinePresenceBoxTab > p {
  margin: 5px 0;
}

#DailyLive {
  background: #f00000;
  font-size: 18px;
  color: white;
  padding: 7px 10px;
  border-radius: 20px;
}

/* Exam catagories */

#ExamCate {
  margin: 0px 0 40px 0;
  height: fit-content;
  background: #f9faf7;
}

#ExamBox {
  margin: auto;
  width: 80vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* background: gray; */
}
#ExamBox > h1 {
  padding: 50px 0;
  color: #222f38;
  font-size: 40px;
}
.examBoxTab {
  height: 400px;
  /* background: rebeccapurple; */
}

/* CSS */
#WhatsappUS {
  background: white;
  width: 50px;
  height: 50px;

  z-index: 20;
  position: fixed;
  bottom: 160px;
  right: 80px;
  border-radius: 15px;
}
#WhatsappUS > svg {
  width: 50px;
  fill: green;
}
#AskQuestion {
  align-items: center;
  appearance: none;
  background-clip: padding-box;
  background-color: initial;
  background-image: none;
  border-style: none;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  flex-direction: row;
  flex-shrink: 0;
  font-family: Eina01, sans-serif;
  font-size: 16px;
  font-weight: 800;
  justify-content: center;
  line-height: 24px;
  margin: 0;
  min-height: 64px;
  outline: none;
  overflow: visible;
  padding: 19px 26px;
  pointer-events: auto;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-transform: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
  width: auto;
  word-break: keep-all;
  z-index: 0;
}

#AskQuestion:before,
#AskQuestion:after {
  border-radius: 80px;
}

#AskQuestion:before {
  background-image: linear-gradient(92.83deg, #20f8be 0, #20f8be 100%);
  content: "";
  display: block;
  height: 100%;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -2;
}

#AskQuestion:after {
  background-color: #121212;

  bottom: 4px;
  content: "";
  display: block;
  left: 4px;
  overflow: hidden;
  position: absolute;
  right: 4px;
  top: 4px;
  transition: all 100ms ease-out;
  z-index: -1;
}

#AskQuestion:hover:not(:disabled):before {
  background: linear-gradient(92.83deg, #332cf2 0%, #332cf4 100%);
}

#AskQuestion:hover:not(:disabled):after {
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  transition-timing-function: ease-in;
  opacity: 0;
}

#AskQuestion:active:not(:disabled) {
  color: #ccc;
}

#AskQuestion:active:not(:disabled):before {
  background-image: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.2),
      rgba(0, 0, 0, 0.2)
    ),
    linear-gradient(92.83deg, #14108f 0, #373485 100%);
}

#AskQuestion:active:not(:disabled):after {
  background-image: linear-gradient(#541a0f 0, #0c0d0d 100%);
  bottom: 4px;
  left: 4px;
  right: 4px;
  top: 4px;
}

#AskQuestion:disabled {
  cursor: default;
  opacity: 0.24;
}
#AskQuestion {
  z-index: 20;
  position: fixed;
  bottom: 70px;
  right: 70px;
}
#OfficalDocsPage > h1 {
  font-size: 40px;
  color: #332cf2;
}

#OfficialDocs {
  display: flex;
  overflow: hidden;
  flex-direction: column;

  padding: 0 100px;
}
#OfficialDocs > img {
  height: 500px;
  width: fit-content;
  margin: 10px 0;
  border: solid 2px #000;
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
}
#OfficialDocs > img {
  align-self: center;
}

.DailyLive {
  width: 110px;
}
#HomeChart {
  width: 500px;
}
#HomeMapBox {
  display: flex;
  justify-content: center;
}
#HomeMap {
  width: 50vw;
}
#HomeMapHeading {
  color: #332cf2;
  font-size: 40px;
  padding-top: 40px;
}
#WhoWeAreHeading {
  color: #222f38;
  font-size: 40px;
  padding-top: 40px;
}
#WhoWeAreBox {
  background: #f9faf7;
}

.ag-format-container {
  /* width: 1142px; */
  margin: 0 auto;
}

.ag-courses_box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 50px 0;
}
.ag-courses_item {
  -ms-flex-preferred-size: calc(33.33333% - 30px);
  flex-basis: calc(33.33333% - 30px);

  margin: 0 15px 30px;

  overflow: hidden;

  border-radius: 28px;
}
.ag-courses-item_link {
  display: block;
  padding: 30px 20px;
  background-color: #121212;

  overflow: hidden;

  position: relative;
}
.ag-courses-item_link:hover,
.ag-courses-item_link:hover .ag-courses-item_date {
  text-decoration: none;
  color: #fff;
}
.ag-courses-item_link:hover .ag-courses-item_bg {
  -webkit-transform: scale(10);
  -ms-transform: scale(10);
  transform: scale(10);
}
.ag-courses-item_title {
  min-height: 87px;
  margin: 0 0 25px;

  overflow: hidden;

  font-weight: bold;
  font-size: 30px;
  color: #fff;

  z-index: 2;
  position: relative;
}
.ag-courses-item_date-box {
  font-size: 18px;
  color: #fff;

  z-index: 2;
  position: relative;
}
.ag-courses-item_date {
  font-weight: bold;
  color: #f9b234;

  -webkit-transition: color 0.5s ease;
  -o-transition: color 0.5s ease;
  transition: color 0.5s ease;
}
.ag-courses-item_bg {
  height: 128px;
  width: 128px;
  background-color: #952aff;

  z-index: 1;
  position: absolute;
  top: -75px;
  right: -75px;

  border-radius: 50%;

  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.ag-courses_item:nth-child(2n) .ag-courses-item_bg {
  background-color: #3ecd5e;
}
.ag-courses_item:nth-child(3n) .ag-courses-item_bg {
  background-color: #e44002;
}

#DownloadSection {
  background: #f9faf7;
}
#DownloadSectionHeading {
  color: #222f38;

  padding: 20px 0;
  font-size: 40px;
}
#PlayStore {
  padding-bottom: 20px;
  width: 40rem;
}

@media only screen and (max-width: 979px) {
  .ag-courses_item {
    -ms-flex-preferred-size: calc(50% - 30px);
    flex-basis: calc(50% - 30px);
  }
  .ag-courses-item_title {
    font-size: 24px;
  }
}

@media only screen and (max-width: 767px) {
  .ag-format-container {
    width: 96%;
  }
}
@media only screen and (max-width: 639px) {
  .ag-courses_item {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
  }
  .ag-courses-item_title {
    min-height: 72px;
    line-height: 1;

    font-size: 24px;
  }
  .ag-courses-item_link {
    padding: 22px 40px;
  }
  .ag-courses-item_date-box {
    font-size: 16px;
  }
}

@media (min-width: 768px) {
  #AskQuestion {
    padding: 19px 32px;
  }
}
@media (max-width: 768px) {
  #HomeMap {
    width: 80vw;
  }
  .info {
    flex-wrap: wrap;
    height: auto;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .infoBox {
    flex-wrap: wrap;
    margin: 0;
    width: auto;
    flex-direction: column;
  }
  .OnlinePresence {
    flex-wrap: wrap;
    height: auto;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .OnlinePresenceBox {
    margin: 0;
    padding: 0;
    width: auto;
    flex-direction: column;
  }

  #HeroBox > h1 {
    font-size: 22px;
    margin: 0 10px;
    margin-top: 20px;
  }
  #HeroBox > h2 {
    font-size: 18px;
    margin: 0 10px;
  }
  #HeroBox {
    padding: 7vh 10px;
  }
  #HeroBookSession {
    font-size: 12px;
    /* margin: 0 ??auto; */
    padding: 10px 2px;
    border-radius: 5px;
    margin: 0;
  }
  #OfficialDocs {
    padding: 0;
  }
  #OfficialDocs > img {
    width: 100vw;
    margin: 20px 0;
    height: auto;
    align-self: center;
  }
  #AskQuestion {
    right: -14px;
    bottom: 5px;
    transform: scale(0.7);
  }
  #WhatsappUS {
    right: 15px;
    bottom: 60px;
    transform: scale(0.7);
  }
}
