#StudyMaterialBox {
  background: #f9faf7;
  color: #222f38;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 60px 0;
}
#StudyMaterialBox > h1 {
  font-size: 40px;
  padding: 20px 0;
}

.mainStudyMaterialTab {
  width: 80vw;
  border-radius: 10px;
  color: white;
  cursor: pointer;
  padding: 10px 20px;
  margin: 10px 0;
  text-align: left;
  background-color: rgb(78, 78, 255);
  font-size: large;
}

.StudyMaterialSubTab {
  display: none;
  cursor: pointer;
  width: 80vw;

  color: black;

  margin: 10px auto;
  text-align: left;
  font-size: large;
}
.StudyMaterialDatal {
  display: none;
}
.StudyMaterialColumn {
  background-color: rgb(102, 102, 255);
  border-radius: 10px;
  color: white;
  text-align: left;

  cursor: pointer;
  margin: 10px 0;
  padding: 10px 20px;
}
.StudyMaterialData {
  background-color: rgb(200, 200, 255);
  border-radius: 10px;
  width: 80vw;
  display: flex;
  font-size: large;
  border: solid 2px #222f38;
  color: black;
  justify-content: space-between;
  text-align: left;
  margin: 10px auto;
  padding: 8px 50px;
  display: none;
}
.StudyMaterialDatab {
  background-color: rgb(200, 200, 255);
  border-radius: 10px;
  width: 80vw;
  display: flex;
  font-size: large;
  border: solid 2px #222f38;
  color: black;
  justify-content: space-between;
  text-align: left;
  margin: 10px auto;
  padding: 8px 50px;
}

@media (max-width: 720px) {
  .StudyMaterialData {
    flex-direction: column;
    align-items: center;
  }
  .StudyMaterialData > p {
    align-self: start;
  }
  .StudyMaterialDataOptions {
    margin: 2px 0;
  }
}

.StudyMaterialData > p {
  text-align: left;
}
.StudyMaterialData > div {
  border-radius: 10px;
  color: white;
}

.StudyMaterialDataOptions {
  display: flex;
  justify-content: space-evenly;
  width: 200px;
}
.StudyMaterialDataOptions > a {
  padding: 2px 20px;
  color: white;
  background: rgb(57, 57, 255);
  border-radius: 10px;
}
.StudyMaterialDataOptions > svg {
  background: rgb(57, 57, 255);
  border-radius: 10px;
  padding: 5px 20px;
  height: 32px;
  stroke: white;
}

.ArrowDownMoreStudyMaterial {
  width: 0;
  height: 0;
  margin: 0 10px;
  padding: auto;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 10px solid rgb(255, 255, 255); /* Color of the arrow */
  display: inline-block;
}
.NoSubfolder {
  display: flex;
}
