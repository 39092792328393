.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.popup-overlay.fade-in {
  opacity: 1;
}

.popup-overlay.fade-out {
  opacity: 0;
}
.popup-content {
  position: relative;
  background: #fff;
  padding: 20px;
  border-radius: 5px;
  max-width: 90%;
  max-height: 90%;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.popup-content img {
  max-width: 100%;
  max-height: 100%;
  display: block;
  margin: 0 auto;
}
#PopupImg {
  /* display: flex; */
  height: 70vh;
}
.close-btn {
  position: absolute;
  top: 10px;
  right: 22px;
  background: none;
  border: none;
  font-size: 34px;
  font-weight: bolder;
  cursor: pointer;
  color: #333;
}

.close-btn:hover {
  color: #ff0000;
}
