#QueryBox {
  margin: 50px 50px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
#QueryBox > div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 10px 0;
}

#QueryBox > div > h2 {
  text-align: left;
  font-size: 3rem;
}
#QueryBox > div > p {
  text-align: left;
  font-size: 2rem;
}
