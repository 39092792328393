#ProductLinkBox {
  height: 30vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0 40px;
}
#ProductLinkBox > div > h4 > a {
  color: black;
}
#ProductLinkH {
  padding: 0 60px;
  font-size: 32px;
  margin-top: 10px;
  color: #332cff;
}
#ProductNav {
  background: #121212;
  font-size: 20px;
  color: #ffffff;
}
#ProductsNav > ul {
  display: flex;
  align-items: center;
  gap: 20px;
  height: 40px;
  margin: 0 100px;
}
#ProductsNav > ul > li > a {
  color: white;
}
.ActiveNav {
  color: #20f8be;
  border-bottom: solid 3px #20f8be;
}
#ProductBox {
  background: #f9faf7;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
#ProductsBox > div {
  height: 100px;
}
#ProductsBox > div > h2 {
  color: white;
  font-size: 50px;
  padding: 30px 0;
}
#Products {
  display: flex;
  justify-content: center;
  width: 200px;
  margin: 0 100px;
  padding: 20px 10px;
  flex-wrap: wrap;
}
#MainProductBanner {
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  background: white;
  margin: 20px 0;
  border: solid 2px black;
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px,
    rgba(0, 0, 0, 0.07) 0px 4px 4px, rgba(0, 0, 0, 0.07) 0px 8px 8px,
    rgba(0, 0, 0, 0.07) 0px 16px 16px;
  padding: 0 20px;
  text-align: center;
}

#ProductPic {
  display: flex;
  justify-content: center;
  overflow: hidden;
  /* height: 200px; */
  width: 300px;
}
#ProductPic > img {
  display: flex;
  justify-content: center;
  overflow: hidden;
  /* height: 200px; */
  width: 80%;
}
#MainProductBanner {
  object-fit: fill;
}

#MainProductBanner:hover {
}
#ProductSubheading::before {
  content: "";
  position: absolute;
  right: 100%;
  top: 0px;
  width: 0;
  height: 0;
  border-top: 0px solid transparent;
  border-right: 20px solid #20f8be;
  border-bottom: 32px solid transparent;
}
#ProductSubheading::after {
  content: "";
  position: absolute;
  right: 100%;
  bottom: 0px;
  width: 0;
  height: 0;
  border-top: 32px solid transparent;
  border-right: 20px solid #20f8be;
  border-bottom: 0px solid transparent;
}
#ProductSubheading {
  background: #20f8be;
  padding: 2px 10px;

  position: relative;
  /* -moz-border-radius: 10px; */
  /* -webkit-border-radius: 10px; */
  /* border-radius: 10px; */
}
#ProductBanner1 > h4 {
  /* padding: 20px; */
  text-wrap: wrap;
}
#ProductBanner1 {
  margin: 25px 0;
  font-size: 22px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
#ProductBanner2 {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  font-size: 20px;
  color: white;
  margin: 20px 0;
  background: #332cff;
  height: 50px;
  border-radius: 100px;
}
#ProductBanner2Line {
  width: 3px;
  height: 40px;
  background: white;
}

#ProductBanner3 {
  display: flex;
  text-align: left;
  flex-direction: column;
  list-style: disc;
  align-items: flex-start;
  font-size: 14px;
  margin: 10px 40px;
  font-weight: 700;
}
#ProductBanner3 > h5 {
  text-align: left;
}
#ProductBanner4 > h5 {
  text-align: left;
  margin: 10px 0;
}
#ProductBanner5 {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  font-size: 20px;
  color: white;
  margin-top: 20px;
  background: #121212;
  height: 50px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  border-radius: 10px 10px 0 0;
}

#ProductBanner5 > h5 {
  background-image: linear-gradient(
    to right,
    #20f8be,
    #20f8be 50%,
    #ffffff 50%
  );
  background-size: 200% 100%;
  background-position: -100%;
  display: inline-block;
  padding: 5px 0;
  position: relative;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: all 0.3s ease-in-out;
}

#ProductBanner5 > h5:before {
  content: "";
  background: #20f8be;
  display: block;
  position: absolute;
  bottom: -3px;
  left: 0;
  width: 0;
  height: 3px;
  transition: all 0.3s ease-in-out;
}

#ProductBanner5 > h5:hover {
  background-position: 0;
}

#ProductBanner5 > h5:hover::before {
  width: 100%;
}

#ProductBanner5Line {
  background: white;
  height: 40px;
  width: 2px;
}
.buttonProducts {
  cursor: pointer;
}

@media (max-width: 400px) {
  #MainProductBanner {
    width: 90vw;
  }
  #ProductPic {
    margin: auto;
    width: 220px;
  }
}
@media (max-width: 768px) {
  #Products {
    flex-direction: column;
    align-items: center;
    margin: 0;
    width: auto;
  }
}
