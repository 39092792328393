ul {
  list-style: none;
}
.App {
  z-index: 10;
  background-color: #333;
  position: fixed;
  top: 0;
  width: 100%;
}
#navlogo {
  width: 40px;
}
a {
  text-decoration: none;
  color: blue;
  font-size: 18px;
}
.hamburger {
  display: none;
}

#bookSession {
  background: blue;
  color: white;
  padding: 10px 20px;
  text-align: center;
  border-radius: 10px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

/* navbar */
.navbar {
  background-color: #222f38;
  padding: 2px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 40px;
  min-height: 70px;
}

/* logo  */
.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  font-family: Arial, Helvetica, sans-serif;
}

/* ul  */
.navMenu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
  /* margin: 0 20px; */
}
.navLink {
  color: #f9faf7;
  font-size: 1.4rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

/* media queries */

@media screen and (max-width: 780px) {
  /* hamburger */
  .hamburger {
    display: block;
    cursor: pointer;
  }

  .bar {
    background-color: #f9faf7;
    width: 20px;
    height: 3px;
    display: block;
    margin: 5px;
    -webkit-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
  }

  /* adding active class to the bars  */
  .hamburger.active .bar:nth-child(2) {
    opacity: 0;
  }
  .hamburger.active .bar:nth-child(1) {
    transform: translateY(8px) rotate(45deg);
  }
  .hamburger.active .bar:nth-child(3) {
    transform: translateY(-8px) rotate(-45deg);
  }

  /* navMenu */

  .navMenu {
    position: absolute;
    flex-direction: column;
    gap: 0;
    top: 70px;
    left: -100vw;
    text-align: start;
    width: 100vw;
    transition: 0.7s ease-in-out;
    background-color: aliceblue;
    padding: 10px;
  }

  .navMenu.active {
    left: 0%;
  }
  .navLink {
    color: #222f38;
    line-height: 26px;
    font-size: 2rem;
  }
  .navMenu li {
    margin: 10px 0;
  }
}
