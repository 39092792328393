#PaymentQRheading {
  font-size: 40px;
  padding: 20px 50px;
}
#PayContact {
  font-weight: 700;
  color: red;
  font-size: 20px;
  padding: 20px 0;
}
@media (max-width: 768px) {
  #PaymentQRheading {
    font-size: 20px;
    padding: 20px 0px;
  }
  #PayContact {
    font-weight: 700;
    color: red;
    font-size: 18px;
    padding: 20px 0;
  }
}
