/* CSS */
#AskQueryMainBox {
  height: 100vh;
}

.askQueryBox {
  display: flex;
  margin-top: 45vh;
  justify-content: center;
  align-items: center;
}
.askQueryBox > form {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.askQuery {
  width: 800px;
  height: 60px;
  border: solid 2px #332cf2;
  border-radius: 0.5rem;
  outline: none;
  box-shadow: none;
  font-size: 22px;
}
#SearchQueryButton {
  position: absolute;
  right: 10px;
  background: #332cf2;
}

#SearchQueryButton {
  backface-visibility: hidden;
  background: #332cf2;
  border: 0;
  border-radius: 0.375rem;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: Circular, Helvetica, sans-serif;
  font-size: 1.125rem;
  font-weight: 700;
  letter-spacing: -0.01em;
  line-height: 1.3;
  padding: 0.7rem 1rem;
  margin: 20px 0;
  text-align: center;
  text-decoration: none;
  transform: translateZ(0) scale(1);
  transition: transform 0.2s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

#SearchQueryButton:disabled {
  color: #787878;
  cursor: auto;
}

#SearchQueryButton:not(:disabled):hover {
  transform: scale(1.05);
}

#SearchQueryButton:not(:disabled):hover:active {
  transform: scale(1.05) translateY(0.125rem);
}

#SearchQueryButton:focus {
  outline: 0 solid transparent;
}

#SearchQueryButton:focus:before {
  border-width: 0.125rem;
  content: "";
  left: calc(-1 * 0.375rem);
  pointer-events: none;
  position: absolute;
  top: calc(-1 * 0.375rem);
  transition: border-radius;
  user-select: none;
}

#SearchQueryButton:focus:not(:focus-visible) {
  outline: 0 solid transparent;
}

#SearchQueryButton:not(:disabled):active {
  transform: translateY(0.125rem);
}
/*  */

#NewQuestionButton {
  backface-visibility: hidden;
  background: #332cf2;
  border: 0;
  border-radius: 0.375rem;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: Circular, Helvetica, sans-serif;
  font-size: 1.125rem;
  font-weight: 700;
  width: 400px;
  letter-spacing: -0.01em;
  line-height: 1.3;
  padding: 1rem 1.25rem;
  position: relative;
  margin: 20px 0;
  text-align: center;
  text-decoration: none;
  transform: translateZ(0) scale(1);
  transition: transform 0.2s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

#NewQuestionButton:disabled {
  color: #787878;
  cursor: auto;
}

#NewQuestionButton:not(:disabled):hover {
  transform: scale(1.05);
}

#NewQuestionButton:not(:disabled):hover:active {
  transform: scale(1.05) translateY(0.125rem);
}

#NewQuestionButton:focus {
  outline: 0 solid transparent;
}

#NewQuestionButton:focus:before {
  border-width: 0.125rem;
  content: "";
  left: calc(-1 * 0.375rem);
  pointer-events: none;
  position: absolute;
  top: calc(-1 * 0.375rem);
  transition: border-radius;
  user-select: none;
}

#NewQuestionButton:focus:not(:focus-visible) {
  outline: 0 solid transparent;
}

#NewQuestionButton:not(:disabled):active {
  transform: translateY(0.125rem);
}
#QuestionResult {
  text-align: left;
  width: 800px;
  margin: auto;
}

.SearchResult {
  text-align: left;
  height: 50px;
  background: #332cf2;
  color: white;
  display: flex;
  font-weight: 700;
  text-overflow: clip;
  margin: 1px 0;
  z-index: 2;
  align-items: center;
  padding: 0 20px;
}
#AsknewquestionBox {
  color: #332cf2;
  margin: 20px 0;
}
@media (max-width: 768px) {
  .askQuery {
    width: 80vw;
    border: none;
    margin: 10px 0;
    border-radius: 0;
    border-bottom: solid 2px #332cf2;
  }

  #SearchQueryButton {
    display: none;
    /* position: fixed; */
  }
  .askQueryBox {
    margin: 10px 0;
  }

  .askQueryBox > form {
    flex-direction: column;
    justify-content: center;
  }
  #QuestionResult {
    width: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .SearchResult {
    font-size: 16px;
    font-weight: 100;
    background: #fff;
    color: #332cf2;
    height: auto;
    width: 90vw;
  }
  #NewQuestionButton {
    width: 90vw;
    font-size: 18px;
  }
}
