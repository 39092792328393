body {
  line-height: 1.5;
  font-family: "Poppins", sans-serif;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.container {
  max-width: 1170px;
  margin: auto;
}
.row {
  display: flex;
  flex-wrap: wrap;
}
ul {
  list-style: none;
}
#Footer {
  position: static;
  bottom: 0;
}
.footer {
  background-color: #24262b;
  padding: 70px 0;
  width: 100vw;
}
.footer-col {
  width: 20%;
  padding: 0 15px;
}
.footer-col h4 {
  font-size: 18px;
  color: #ffffff;
  text-transform: capitalize;
  margin-bottom: 35px;
  font-weight: 500;
  position: relative;
}
.footer-col h4::before {
  content: "";
  position: absolute;
  left: 11;
  bottom: -10px;
  background-color: rgb(173, 173, 205);
  height: 2px;
  box-sizing: border-box;
  width: 80px;
}
.footer-col ul li:not(:last-child) {
  margin-bottom: 10px;
}
.footer-col ul li a {
  font-size: 16px;
  text-transform: capitalize;
  color: #ffffff;
  text-decoration: none;
  font-weight: 300;
  color: #bbbbbb;
  display: block;
  transition: all 0.3s ease;
}
.footer-col ul li a:hover {
  color: #ffffff;
  padding-left: 8px;
}
.footer-col .social-links {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.footer-col .social-links a svg {
  fill: #fff;
}
.footer-col .social-links a {
  display: inline-block;
  height: 40px;
  width: 40px;
  margin: 0 10px 10px 0;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 40px;
  border-radius: 50%;
  color: #ffffff;
  transition: all 0.5s ease;
}

/*responsive*/
@media (max-width: 767px) {
  .footer-col {
    width: 50%;
    margin-bottom: 30px;
  }
}
@media (max-width: 574px) {
  .footer-col {
    width: 100%;
  }
}

#Threads {
  transform: scale(0.8);
}

/* CSS */
#BookSessionFooter {
  backface-visibility: hidden;
  background: #332cf2;
  border: 0;
  border-radius: 0.375rem;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: Circular, Helvetica, sans-serif;
  font-size: 1.5rem;
  font-weight: 700;
  width: 200px;
  letter-spacing: -0.01em;
  line-height: 1.3;
  padding: 1rem 1.25rem;
  position: relative;
  margin: 20px 0;
  text-align: center;
  text-decoration: none;
  transform: translateZ(0) scale(1);
  transition: transform 0.2s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

#BookSession:disabled {
  color: #787878;
  cursor: auto;
}

#BookSession:not(:disabled):hover {
  transform: scale(1.05);
}

#BookSession:not(:disabled):hover:active {
  transform: scale(1.05) translateY(0.125rem);
}

#BookSession:focus {
  outline: 0 solid transparent;
}

#BookSession:focus:before {
  border-width: 0.125rem;
  content: "";
  left: calc(-1 * 0.375rem);
  pointer-events: none;
  position: absolute;
  top: calc(-1 * 0.375rem);
  transition: border-radius;
  user-select: none;
}

#BookSession:focus:not(:focus-visible) {
  outline: 0 solid transparent;
}

#BookSession:not(:disabled):active {
  transform: translateY(0.125rem);
}
