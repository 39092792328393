/*  */

.main-content {
  padding: 20px;
}

.main-content section {
  margin-bottom: 30px;
  width: 98vw;
}

.main-content h2 {
  font-size: 1.5em;
  color: #007bff;
  border-bottom: 2px solid #007bff;
  padding-bottom: 5px;
  margin-bottom: 20px;
}

.main-content ul {
  padding-left: 20px;
}

.main-content li {
  text-align: left;
  margin-bottom: 15px;
}

.main-content li strong {
  color: #0056b3;
}

.main-content a {
  color: #0056b3;
  text-decoration: underline;
}

.main-content a:hover {
  color: red;
}

.blog-footer {
  background-color: #007bff;
  color: #ffffff;
  padding: 20px;
  text-align: center;
  border-radius: 8px;
  margin-top: 20px;
}

/*  */
#Blog {
  margin: auto;
}
.blog-header {
  background-color: #007bff;
  color: #ffffff;
  padding: 20px;
  text-align: center;
  border-radius: 8px;
}
.main-content {
  font-size: 2rem;
}
.main-content > section {
  background: white;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.main-content > section > img {
  width: 100%;
}
.main-content > section > ul > li {
  text-align: justify;
}
.main-content > section > ul > ul > li {
  text-align: justify;
  list-style: decimal;
}
.main-content > section > ul > ul > li > ul > li {
  list-style: disc;
  text-align: justify;
}
.main-content > section > ul {
  margin: 10px 0px;
}
.main-content > section > p {
  font-size: 1.5rem;
  text-align: left;
  font-weight: 500;
}

.main-content > section > h2 {
  font-size: 3rem;
  text-align: left;
}

.main-content > section > ul > li > a {
  text-align: justify;
  text-decoration: none;
  margin: 0;
}
#Blog-Note {
  color: red;
  font-size: medium;
  font-weight: 600;
}
.blog-footer {
  font-size: 2rem;
  font-weight: 500;
  color: #202002;
  margin: 20px 0;
}
/* Blogs.css */

#Blog {
  font-family: Arial, sans-serif;
  padding: 20px;
  margin: auto;
  max-width: 1200px;
}

.main-content {
  text-align: justify;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.exam-details,
.blog-syllabus,
.blog-tips {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.exam-details ul,
.blog-syllabus ul,
.blog-tips ul {
  padding-left: 20px;
}

.blog-footer {
  text-align: center;
  padding: 20px;
  margin-top: 20px;
  border-radius: 8px;
}
.blog-footer > a {
  text-align: justify;
  color: #f2f2f2;
}
.blog-footer > p {
  color: #f2f2f2;
}
.expectedCutoff {
  width: 100%;
  border-collapse: collapse;
  margin: 25px 0;
  font-size: 18px;
  text-align: left;
}
table th,
table td {
  padding: 12px;
  border-bottom: 1px solid #ddd;
}
table th {
  background-color: #f2f2f2;
}
/* Responsive Styles */
@media (min-width: 768px) {
  .main-content {
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
  }

  .exam-details,
  .blog-syllabus,
  .blog-tips {
    flex: 1;
    margin: 0 10px;
    min-width: calc(33% - 20px);
  }
}

@media (max-width: 767px) {
  #Blog {
    padding: 10px 2px;
  }
  .blog-header {
    width: 99vw;
    margin: auto;
    margin: 10px 0;
  }
  .blog-footer {
    margin: auto;
    width: 99vw;
    margin: 10px 0px;
  }
  .main-content {
    padding: 0 10px;
  }
  .main-content section {
    width: 95vw;
    padding: 20px 10px;
  }
  .main-content ul {
    padding: 2px;
  }
  .main-content > section > ul > ul {
    padding-left: 10px;
  }
}

#BlogLinkBox {
  display: flex;
  padding: 40px 20px;
  width: 500px;
  background: white;
  border: solid 2px #222f38;
  border-radius: 10px;
  margin: 10px 0;
  justify-content: space-between;
  align-items: center;
}

#BlogLinkBox > h1 {
  text-align: justify;
  width: 320px;
  font-size: 20px;
  color: #222f38;
  text-wrap: wrap;
}

#BlogLinkBox > button {
  color: white;
  background: #222f38;
  padding: 8px 20px;
  border-radius: 2px;
  cursor: pointer;
}

#BlogPage {
  display: flex;
  background: #f9faf7;
  flex-direction: column;
  align-items: center;
}
#BlogPage > div > h1 {
  font-size: 4rem;
}
@media (max-width: 500px) {
  #BlogLinkBox {
    width: 90vw;
    flex-direction: column;
    padding: 10px 4px;
    gap: 5px;
  }

  #BlogLinkBox > h1 {
    text-align: center;
    width: auto;
    font-size: 16px;
  }
}
