.carrier {
  background-color: #f9faf7;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  margin: 110px auto;
}
.carrier > h1 {
  font-size: 40px;
  color: #222f38;
}
.carrier > img {
  width: 100%;
}
.carrier > h2 {
  text-align: left;
  font-size: 28px;
  color: #222f38;
}

.career-list {
  list-style: decimal;

  padding: 0 20px;
}

.career-list > li {
  margin: 10px 0;
  font-size: 18px;
  text-align: left;
}

.requirments-list {
  padding: 0 20px;
  color: red;
  font-weight: 700;
}
.requirments-list2 {
  padding: 0 20px;
  color: red;
  font-weight: 700;
}

.requirments-list > li {
  list-style: disc;
  margin: 10px 0;
  font-size: 18px;
  text-align: left;
}
.requirments-list2 > li {
  margin: 10px 0;
  font-size: 18px;
  text-align: left;
}
#OrCarrer {
  text-align: center;
}
#GoogleFormLinkCareer {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  color: #fff;

  background-color: #0070e0;
}

.sub-list {
  list-style: lower-alpha;
  margin-left: 20px;
  text-align: left;
  margin-top: 5px;
}

.sub-list > li {
  font-size: 16px;
  text-align: left;
  margin: 5px 0;
}
