#BookSessionPage {
  background: #f9faf7;
  display: flex;
  justify-content: center;
  align-items: center;
}
#BookSessionBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: white;
  border-radius: 20px;
  width: 500px;
  padding: 6px 0;
}
#BookSessionHeading {
  margin: 15px 0;
  font-size: 22px;
  color: #332cf2;
}
.BookSessionInput {
  border: none;
  background: transparent;
  margin: 10px 0;
  font-size: 20px;
  text-align: left;
  text-align-last: left;
  padding: 5px 20px;
  width: 250px;
  border-bottom: 2px solid black;
}

.BookSessionInput:focus > option {
  text-align: left;
}
.BookSessionInput:focus {
  outline: none;
  box-shadow: none;
}

.BookSessionInput::placeholder {
  color: black;
}
.BookSessionInput > option {
  color: rgb(41, 41, 150);
}
/*  */
#BookSessionSubmit {
  backface-visibility: hidden;
  background: #332cf2;
  border: 0;
  border-radius: 0.375rem;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: Circular, Helvetica, sans-serif;
  font-size: 18px;
  font-weight: 700;
  width: 200px;
  letter-spacing: -0.01em;
  line-height: 1.3;
  padding: 0.5rem 1.25rem;
  position: relative;
  margin: 30px 0;
  text-align: center;
  text-decoration: none;
  transform: translateZ(0) scale(1);
  transition: transform 0.2s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

#BookSessionSubmit:disabled {
  color: #787878;
  cursor: auto;
}

#BookSessionSubmit:not(:disabled):hover {
  transform: scale(1.05);
}

#BookSessionSubmit:not(:disabled):hover:active {
  transform: scale(1.05) translateY(0.125rem);
}

#BookSessionSubmit:focus {
  outline: 0 solid transparent;
}

#BookSessionSubmit:focus:before {
  border-width: 0.125rem;
  content: "";
  left: calc(-1 * 0.375rem);
  pointer-events: none;
  position: absolute;
  top: calc(-1 * 0.375rem);
  transition: border-radius;
  user-select: none;
}

#BookSessionSubmit:focus:not(:focus-visible) {
  outline: 0 solid transparent;
}

#BookSessionSubmit:not(:disabled):active {
  transform: translateY(0.125rem);
}

#BookSessionInputs {
  justify-content: center;
  align-items: center;
  gap: 30px;
  flex-wrap: wrap;
}
.TeachersInfoTab {
  display: flex;
  background: #2c097f;
  color: #fff;
  align-items: center;
  border-radius: 20px;
  padding: 10px 50px;
  margin: 20px 0;
}
.TeachersInfoTab > div > img {
  width: 150px;
  height: 150px;
  object-fit: contain;
  background: #fff;
  border-radius: 100%;
  border: solid 2px black;
}
#TeachersDetailBox {
  display: flex;
  align-items: left;
  justify-content: center;
  flex-direction: column;
  margin: 0 20px;
  font-size: 20px;
  text-align: left;
}
#TeachersDetailBox > div > h3 {
  color: #fff;
  text-align: left;
  font-size: 18px;
}
#TeachersDetailBox > div > div {
  text-align: left;
  /* color: #332cf2; */
  cursor: pointer;
  font-size: 14px;
}

#BookClassBtnTeacher {
  margin: 10px 0;
  background: #332cf2;
  border-radius: 10px;
  color: white;
  font-size: 20px;
  padding: 4px 20px;
  box-shadow: 0 0 0 1px rgb(24, 24, 24), 2px 2px 0 0 rgb(74, 74, 74);
}
#BookClassBtnTeacher > p {
  font-size: 18px;
}
#BookClassBtnTeacher:active {
  animation: buttonClick 0.4s ease-in-out;
}
@keyframes buttonClick {
  50% {
    box-shadow: none;
  }
  100% {
    box-shadow: 0 0 0 1px rgb(24, 24, 24), 4px 4px 0 0 rgb(74, 74, 74);
  }
}
#TeachersPopupInfo {
  position: absolute;
  top: 10px;
  background: white;
  border: solid 2px #332cf2;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  padding: 20vh 10vw;
}
#TeachersPopupInfo > div > h2 {
  font-size: 40px;
}

#TeachersPopupInfo > div > div > img {
  width: 300px;
  border: #332cf2 solid 2px;
  border-radius: 100%;
}
#CloseTeachersInfo {
  position: absolute;
  top: 14vh;
  font-size: 32px;
  background: #332cf2;
  /* border: solid 2px black; */
  width: 150px;
  height: 50px;
  display: flex;
  padding: 2px 0;
  justify-content: center;
  align-items: center;
  color: white;
  border-radius: 20px;
  animation: swipedown 0.5s forwards;
}
#BookSessionBox {
  margin: 40px 0;
}

@keyframes swipedown {
  0% {
    top: 0;
  }
  50% {
    top: 16vh;
  }
  70% {
    top: 10vh;
  }
  100% {
    top: 14vh;
  }
}
#SelectTeacherHeading {
  color: #222f38;
  font-size: 40px;
}
#TeachersToSelect > h1 {
  font-size: 2rem;
  color: #222f38;
}

@media (max-width: 720px) {
  #CloseTeachersInfo {
    top: 20vh;
  }
  #TeachersPopupInfo {
    display: flex;
    overflow: hidden;
    width: 100vw;
    flex-direction: column;
  }
  #YTVideo {
    /* width: 100px; */
  }
  #SelectTeacherHeading {
    font-size: 30px;
  }
  .TeachersInfoTab {
    flex-direction: column;
    width: 90vw;
    padding: 10px 0;
  }
  #BookSessionBox {
    width: 90vw;
    margin: 40px 0;
  }

  .BookSessionInput {
    font-size: 18px;
  }
}
