.results-slideshow-container {
  position: relative;
  background: #5d17eb;
  padding: 5px 0;
  margin: 0 30vw;
}

.results-mySlides {
  display: none;
  text-align: center;
}

.results-mySlides > img {
  width: 400px;
}

/* Next & previous buttons */
.results-prev,
.results-next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  margin-top: -30px;
  padding: 10px;
  color: black;
  font-weight: bold;
  font-size: 12px;
  border-radius: 0 3px 3px 0;
  user-select: none;
}
.results-prev {
  position: absolute;
  left: 0;
  border-radius: 3px 0 0 3px;
}
/* Position the "next button" to the right */
.results-next {
  position: absolute;
  right: 0;
  border-radius: 3px 0 0 3px;
}

/* On hover, add a black background color with a little bit see-through */
.results-prev:hover,
.results-next:hover {
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
}
@media (max-width: 768px) {
  .results-slideshow-container {
    margin: 0;
  }
  .results-mySlides > img {
    width: 100vw;
  }
}
