#CommentBox {
  margin: 20px 0;
}
#CommentBox div h1 {
  font-size: 40px;
  color: #332cf2;
}
#CommentBox div p {
  font-size: 22px;
}
.textFeilds {
  width: 40vw;
  font-size: 22px;
  text-align: left;

  padding: 0 10px;
  margin: 12px 0;
}
#CommentName::before {
  color: red;
  content: "*sdfsdfsdfsdsdf";
}

#CommentEmail::before {
  color: red;
  content: "*";
}
#CommentBox div textarea {
  resize: none;
  width: 40vw;
  font-size: 22px;
  text-align: left;
  padding: 0 10px;
  margin: 2px;
}
#CommentFeilds {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
#CommentZone {
  text-wrap: wrap;
  height: 200px;
  width: 300px;
}
#Checkbox {
  margin: 0px 2px;
}
#CheckboxText {
  font-size: 12px;
}

#PostCommentBtn {
  background: transparent;
  border-radius: 0;
  font-size: 22px;
  border: solid 2px black;
  padding: 4px 10px;
  margin: 20px 0;
  cursor: pointer;
}
#ErrorMessage {
  font-size: 12px;
  color: red;
}

@media (max-width: 768px) {
  #CommentFeilds {
    flex-direction: column;
  }
  .textFeilds {
    width: 80vw;
    font-size: 18px;
  }
  #CommentBox div textarea {
    width: 80vw;
    font-size: 18px;
  }
}
