#SignupPage {
  /* background: rgb(41, 41, 150); */
  height: 90vh;
  display: flex;
  background: #f9faf7;
}
#Signup {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #f9faf7;
  margin: auto;
  width: 500px;
  border-radius: 20px;
}
.SignupBox {
  display: flex;
  flex-direction: column;
  align-items: center;
}
#Signup > h2 {
  font-size: 32px;
  margin: 20px 0;
  color: #222f38;
}
.SignupBox > div > input {
  border: none;
  background: transparent;
  margin: 15px 0px;
  margin-left: 20px;
  font-size: 16px;
  text-align: left;
  width: 180px;
}
.SignupBox > div > select {
  border: none;
  background: transparent;
  margin: 15px 0px;
  margin-left: 20px;
  font-size: 16px;

  text-align: left;
  width: 180px;
}
.SignupBox > div > opet {
  border: none;
  background: transparent;
  margin: 15px 0px;
  margin-left: 20px;
  font-size: 16px;

  text-align: left;
  width: 180px;
}
.SignupBox > button {
  border: none;
  background: transparent;
  margin: 30px 0;
  font-size: 18px;
  text-align: left;

  background: blue;
  padding: 6px 0;
  text-align: center;
  border-radius: 20px;
  color: white;
  width: 220px;
}
.SignupBox > div > input:focus {
  background: transparent;
  border: none;
  outline: none;
}
.SignupBox > div > select:focus {
  background: transparent;
  border: none;
  outline: none;
}

.SignupBox > div > input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 50px white inset; /*your box-shadow*/
}
.SignupBox > div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.FormIcons {
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  fill: blue;
}

.ErrorMessage {
  font-size: 12px;
  color: blue;
  font-weight: bolder;
  display: inline;
  text-align: left;
}
.formOptions {
  text-align: left;
}

.login-with-google-btn {
  transition: background-color 0.3s, box-shadow 0.3s;
  cursor: pointer;

  padding: 12px 16px 12px 42px;
  border: none;
  border-radius: 3px;
  box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 1px 1px rgba(0, 0, 0, 0.25);

  color: #757575;
  font-size: 14px;
  font-weight: 500;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;

  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMTcuNiA5LjJsLS4xLTEuOEg5djMuNGg0LjhDMTMuNiAxMiAxMyAxMyAxMiAxMy42djIuMmgzYTguOCA4LjggMCAwIDAgMi42LTYuNnoiIGZpbGw9IiM0Mjg1RjQiIGZpbGwtcnVsZT0ibm9uemVybyIvPjxwYXRoIGQ9Ik05IDE4YzIuNCAwIDQuNS0uOCA2LTIuMmwtMy0yLjJhNS40IDUuNCAwIDAgMS04LTIuOUgxVjEzYTkgOSAwIDAgMCA4IDV6IiBmaWxsPSIjMzRBODUzIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNNCAxMC43YTUuNCA1LjQgMCAwIDEgMC0zLjRWNUgxYTkgOSAwIDAgMCAwIDhsMy0yLjN6IiBmaWxsPSIjRkJCQzA1IiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNOSAzLjZjMS4zIDAgMi41LjQgMy40IDEuM0wxNSAyLjNBOSA5IDAgMCAwIDEgNWwzIDIuNGE1LjQgNS40IDAgMCAxIDUtMy43eiIgZmlsbD0iI0VBNDMzNSIgZmlsbC1ydWxlPSJub256ZXJvIi8+PHBhdGggZD0iTTAgMGgxOHYxOEgweiIvPjwvZz48L3N2Zz4=);
  background-color: white;
  background-repeat: no-repeat;
  background-position: 12px 11px;

  &:hover {
    box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 2px 4px rgba(0, 0, 0, 0.25);
  }

  &:active {
    background-color: #eeeeee;
  }

  &:focus {
    outline: none;
    box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 2px 4px rgba(0, 0, 0, 0.25),
      0 0 0 3px #c8dafc;
  }

  &:disabled {
    filter: grayscale(100%);
    background-color: #ebebeb;
    box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 1px 1px rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
  }
}
#GoogleSignin {
  background: white;
  border-radius: 10px;
  box-shadow: 0 26px 58px 0 rgba(0, 0, 0, 0.22),
    0 5px 14px 0 rgba(0, 0, 0, 0.18);
  padding: 50px 80px;
  display: flex;
  flex-direction: column;
  /* gap: 10px; */
  justify-content: center;
  align-items: center;
  margin: auto;
}
#GoogleSignin > img {
  width: 20rem;
  margin-bottom: 20px;
}
.login-with-google-btn {
  transform: scale(1.2);
}
#GoogleSignin > h1 {
  font-size: 36px;
  line-height: 16px;
  margin: 0;
  color: black;
}
#GoogleSignin > h2 {
  font-size: 32px;
  color: black;
}
@media (max-width: 700px) {
  #Signup {
    border-radius: 0;
  }
  .SignupBox > button {
    font-size: 24px;
    border-radius: 5px;
  }
}
