.booksSection {
  display: grid;
  justify-content: center;
}

.BookTab {
  display: flex;
  border: solid 1px black;
  color: black;
  width: 550px;
  margin: 1rem 0rem;
}

.BookImage {
  display: flex;
}
.BookImage > img {
  width: 20rem;
}
.BookContent {
  text-align: left;
  margin: 1rem 2rem;
}
.BookContent > h2 {
  text-align: left;
  font-size: 2rem;
}
.BookContent > p {
  text-align: left;
  font-size: 1.2rem;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  color: teal;
}
.BookContent > div {
  display: flex;
  position: relative;
  align-items: center;
  margin: 2px 10px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}
.BookContent > div > h4 {
  font-size: 1.6rem;
}
.BookContent > div > div {
  font-size: 1.2rem;
  color: gray;
  margin: 0 10px;
}
.BookContent > div > div::after {
  border-top: 1px solid;
  border-color: grey;
  position: absolute;
  content: "";
  width: 6.8rem;
  right: 0;
  top: 50%;
  left: 26%;
}
.BookDeliveryTime {
  text-align: left;
  font-size: 1.3rem;
  font-family: Arial, Helvetica, sans-serif;
  color: rgb(9, 97, 126);
}
#BookRating {
  font-size: 20px;
}
.halfStar {
  background: linear-gradient(to right, orange 10%, rgb(152, 152, 152) 0%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.oneStar {
  background: linear-gradient(to right, orange 20%, rgb(152, 152, 152) 0%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.oneAndHalfStar {
  background: linear-gradient(to right, orange 30%, rgb(152, 152, 152) 0%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.twoStar {
  background: linear-gradient(to right, orange 40%, rgb(152, 152, 152) 0%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.twoAndHalfStar {
  background: linear-gradient(to right, orange 50%, rgb(152, 152, 152) 0%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.threeStar {
  background: linear-gradient(to right, orange 60%, rgb(152, 152, 152) 0%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.threeAndHalfStar {
  background: linear-gradient(to right, orange 70%, rgb(152, 152, 152) 0%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.fourStar {
  background: linear-gradient(to right, orange 80%, rgb(152, 152, 152) 0%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.fourAndHalfStar {
  background: linear-gradient(to right, orange 90%, rgb(152, 152, 152) 0%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.fiveStar {
  background: linear-gradient(to right, orange 100%, rgb(152, 152, 152) 0%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* BOOK DISCRIPTION PAGE */
#BookDiscriptionPage {
  display: flex;
  flex-wrap: wrap;

  justify-content: space-evenly;
  padding: 20px 0;
}

.BookDiscriptionContent {
  width: 500px;
}
.BookDiscriptionContent > h2 {
  text-align: left;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 300;
  font-size: 4rem;
}
.BookDiscriptionContent > p {
  text-align: left;
  color: grey;
  font-size: 2rem;
}
.BookRatingDiv {
  display: flex;
  justify-content: left;
}
.BookContentDisciption > p {
  text-align: justify;
  font-size: 1.8rem;
  font-family: sans-serif;
}
.BookTypesBox {
  display: grid;
  color: black;
  font-size: 1.4rem;
  grid-template-columns: 20% 20% 20% 20%;
  justify-content: space-between;
  margin: 30px 0;
}
.BookTypesBox > div > h5 {
  font-size: 1.4rem;
}
.BookTypesBox > div {
  display: grid;
  justify-content: center;
  align-items: center;

  background: #333;
  color: white;
  border-radius: 5px;
  padding: 10px 2px;
}
.BookDetails {
  width: 400px;
  font-size: 2rem;
  border-radius: 20px;
  padding: 50px 30px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}
.BookDetails > p {
  text-align: left;
  margin: 10px 0;
}
.BookPricing {
  position: relative;
}
.BookPricing > h4 {
  text-align: left;
  font-size: 3rem;
}
.BookPricing > div {
  text-align: left;
  color: gray;
  font-size: 2rem;
}
.BookPricing > div::after {
  border-top: 1px solid;
  border-color: grey;
  position: absolute;
  content: "";
  width: 10.7rem;
  right: 0;
  top: 80%;
  left: 0;
}
.BookDeliveryLocation {
  color: rgb(0, 99, 132);
  font-size: 2rem;
  text-align: left;
}
.BookDeliveryInstock {
  text-align: left;
  font-family: monospace;
  padding: 10px 0;
  color: grey;
  font-size: 1.8rem;
}
.BookShippingDetails {
  font-size: 1.7rem;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-weight: bold;
}
.BookBuyingDetails {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.BookQuantityDetails {
  display: flex;
  align-items: center;
}
.BookQuantityDetails > input {
  width: 35px;
  height: 25px;
  margin: 20px 10px;
}
.BookBuyingDetails > button {
  width: 200px;
  height: 40px;
  margin: 5px 0;
  font-size: 1.7rem;
  border-radius: 5px;
}
#BookBuyNow {
  background: orange;
  border: none;
}
#BookAddToCart {
  background: #333;
  color: white;
}
.BookDiscriptionImage {
  width: 400px;
}
.BookDiscriptionImage > img {
  width: 380px;
  border: solid 1px black;
}
.bookPreviewImages {
  width: 40px;
  margin: 1rem 1rem;
  border: solid 1px black;
  border-radius: 7px;
}
/*  */

@media (max-width: 720px) {
  .BookTab {
    width: 100%;
  }
  .BookImage {
    width: 150px;
    height: 200px;
  }
  .BookDiscriptionImage {
    width: 100vw;
  }
  .BookDiscriptionImage > img {
    width: 70vw;
    border: solid 1px black;
  }
  #BookDiscriptionPage {
    padding: 10px;
  }
}
