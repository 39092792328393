.yt-slideshow-container>h1{
    font-size: 40px;
    color: #f9b234;
    padding: 5px 0;
}
.yt-slideshow-container {
    position: relative;
    background: #121212;
    padding: 20px 100px;
  }

  .YTcontrols{
    position: relative;
    bottom: 160px;
  }
.prev,
.next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  margin-top: -30px;
  padding: 16px;
  color: white;
  font-weight: bold;
  font-size: 20px;
  border-radius: 0 3px 3px 0;
  user-select: none;
  background: #000;
}
@media (max-width: 768px) {
.yt-slideshow-container{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.yt-mySlides{
        display: none;
        display: flex;
        text-align: center;
    
}
.yt-mySlides>iframe{
    width: 80vw;
}
}