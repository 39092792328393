/* Reset */
html {
  font-size: 62.5%;
}
@media (max-width: 75em) {
  html {
    font-size: 56.25%;
  }
}
@media (max-width: 56.25em) {
  html {
    font-size: 50%;
  }
}
@media (min-width: 112.5em) {
  html {
    font-size: 75%;
  }
}
.TeamPage {
  background: #f9faf7;
}

#TeamHeading {
  color: #222f38;
  font-size: 32px;
  padding: 60px 0 0 0;
}
.artboard {
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  height: 100%;
  position: relative;
}
@media (max-width: 37.5em) {
  .artboard {
    padding: 1rem;
  }
}
.card {
  flex: initial;
  position: relative;
  height: 50rem;
  width: 40rem;
  -moz-perspective: 200rem;
  perspective: 200rem;
  margin: 2rem;
}
.card__side {
  height: 50rem;
  transition: all 0.8s ease;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  -webkit-backface-visibility: hidden;
  /* We don't want to see the back part of the element. */
  backface-visibility: hidden;
  /* We don't want to see the back part of the element. */
  border-radius: 3px;
  overflow: hidden;
  /* The image is overflowing the parent. */
  box-shadow: 0 2rem 6rem rgba(0, 0, 0, 0.15);
}
.card__side--front {
  background: #fff;

  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.card__side--back {
  background-color: #fff;
  transform: rotateY(180deg);
}
.card:hover .card__side--back {
  transform: rotateY(0);
}
.card:hover .card__side--front {
  transform: rotateY(-180deg);
}
.card__theme {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 54%;
  width: 90%;
  text-align: center;
}
.card__theme-box {
  color: #332cf2;
  margin-bottom: 8rem;
  font-weight: 700;
}
.card__subject {
  font-size: 1.6rem;
  padding: 12px 0;
  margin-bottom: 1rem;
}
.card__title {
  display: flex;
  justify-content: center;
  height: 35rem;
}
.card__title > img {
  border-radius: 40rem;
}
.card__cover {
  position: relative;
  background-size: cover;
  height: 14rem;
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%);
  clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%);
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  background-color: #000;
}
.card__heading {
  text-align: center;
  color: #20f8be;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 75%;
}
.card__heading-span {
  font-size: 4.2rem;
  font-weight: 300;
  text-transform: uppercase;
  padding: 1rem 1.5rem;
  color: #20f8be;
}
.card__details {
  font-family: "Inconsolata", monospace;
  padding: 4rem 2rem;
}
.card__details ul {
  list-style: none;
  width: 80%;
  margin: 0 auto;
}
.card__details ul li {
  text-align: center;
  font-size: 1.3rem;
  padding: 1rem;
}
.card__details ul li:not(:last-child) {
  border-bottom: 1px solid #eee;
}
@media only screen and (max-width: 37.5em), only screen and (hover: none) {
  .card {
    height: auto;
    border-radius: 3px;
    background-color: #fff;
    box-shadow: 0 2rem 6rem rgba(0, 0, 0, 0.15);
  }
  .card__side {
    height: auto;
    position: relative;
    box-shadow: none;
  }
  .card__side--front {
    clip-path: polygon(0 15%, 100% 0, 100% 100%, 0 100%);
  }
  .card__side--back {
    transform: rotateY(0);
  }
  .card:hover .card__side--front {
    transform: rotateY(0);
  }
  .card__details {
    padding: 3rem 2rem;
  }
  .card__theme {
    position: relative;
    top: 0;
    left: 0;
    transform: translate(0);
    width: 100%;
    padding: 5rem 4rem 1.5rem 4rem;
    text-align: right;
  }
  .card__theme-box {
    margin-bottom: 1.5rem;
  }
  .card__title {
    display: flex;
  }
}
