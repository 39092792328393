#toast {
  visibility: hidden;
  max-width: 350px;
  /* height: 50px; */
  /*margin-left: -125px;*/
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin: auto;
  background-color: #332cf2;
  color: #000;
  text-align: center;
  border-radius: 2px;
  position: fixed;
  z-index: 1;
  left: 0;
  right: 0;
  bottom: 30px;
  font-size: 17px;
  white-space: nowrap;
}
#toast #img {
  display: flex;
  align-items: center;
  width: 50px;
  height: 50px;

  float: left;

  box-sizing: border-box;

  background-color: #111;
  color: #fff;
}
#toast #desc {
  color: #fff;
  display: flex;
  justify-content: center;
  padding: 16px;

  overflow: hidden;
  white-space: wrap;
}

#toast.show {
  visibility: visible;
  -webkit-animation: fadein 0.5s, stay 3s 0.5s, fadeout 0.5s 1.5s;
  animation: fadein 0.5s, stay 3s 0.5s, fadeout 0.5s 1.5s;
}

@-webkit-keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 30px;
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 30px;
    opacity: 1;
  }
}

@-webkit-keyframes stay {
  from {
    min-width: 350px;
  }
  to {
    min-width: 350px;
  }
}

@keyframes stay {
  from {
    min-width: 350px;
  }
  to {
    min-width: 350px;
  }
}

@-webkit-keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }
  to {
    bottom: 0px;
    opacity: 0;
  }
}

@keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }
  to {
    bottom: 0px;
    opacity: 0;
  }
}
